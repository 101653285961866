import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/layout"
import { LoremIpsum } from "lorem-ipsum"
import { destinations } from "../data"
import Seo from "../components/seo"

const lorem = new LoremIpsum({
  sentencesPerParagraph: { max: 8, min: 4 },
  wordsPerSentence: { max: 16, min: 4 },
})

export default function Destination({ pageContext: { slug }, data }) {
  const { name: pageName } =
    destinations.filter(destination => destination.slug === slug)[0] || {}

  const image = getImage(data.imageSharp)
  return (
    <Layout hasHeader={true} selected={slug}>
      <Seo title={pageName} />

      <h1>{pageName}</h1>
      <div>
        <GatsbyImage image={image} alt={slug} />
        <Link to="/reservation" title="Reservation">
          Réservez
        </Link>
        {lorem
          .generateParagraphs(4)
          .split("\n")
          .map((paragraph, index) => (
            <p key={`paragarph-${index}`}>{paragraph}</p>
          ))}

        <div>
          {Array.from({ length: 6 }).map((_, index) => (
            <GatsbyImage image={image} alt={slug} key={`image-${index}`} />
          ))}
        </div>

        {lorem
          .generateParagraphs(6)
          .split("\n")
          .map((paragraph, index) => (
            <p key={`paragarph-${index}`}>{paragraph}</p>
          ))}
        <Link to="/reservation" title="Reservation">
          Réservez
        </Link>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query GetDestinationImage($regex: String) {
    imageSharp(gatsbyImageData: {}, original: { src: { regex: $regex } }) {
      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, quality: 100)
    }
  }
`
